import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';

function remindAboutImpersonation(app) {
  const action = {
    text: 'Stop',
    onClick: () => {
      stopImpersonation(app);
    },
  };
  app.$toasted.success(`Impersonating ${app.$store.getters.impersonatedUserEmailState}`, {action});
}

export function setImpersonation(app, userEmail) {
  app.$store.commit('setImpersonatedUserState', {email: userEmail});
  app.$store.commit('clearActiveFamilyTreeIdState');
  app.$toasted.success(`Impersonated ${app.$store.getters.impersonatedUserEmailState}`);
  AnalyticsAmplitudeHandler.disable();
}

export function stopImpersonation(app) {
  app.$router.push({name: 'main-page'}).catch(() => {});
  app.$store.commit('setImpersonatedUserState', null);
  app.$store.commit('setImpersonatedUserFamilyTreeIdState', null);
  app.$store.commit('clearActiveFamilyTreeIdState');
  app.$toasted.success('Impersonation stopped');
  AnalyticsAmplitudeHandler.init(process.env.VUE_APP_AMPLITUDE_ID);
  if (app.$store.getters.userEmailState) {
    AnalyticsAmplitudeHandler.setUserId(app.$store.getters.userEmailState);
  }
}

export function handleImpersonationOnRouteChange(from, to, app) {
  if (!from.name && app.$store.getters.impersonatedUserEmailState && !to.query.impersonate) {
    return remindAboutImpersonation(app);
  }
  if (to.query.impersonate) {
    if (app.$store.getters.userIsStaffState) {
      return setImpersonation(app, to.query.impersonate);
    }
    app.$toasted.error(`Log in as staff to impersonate ${to.query.impersonate}`, {duration: 5000});
  }
}
