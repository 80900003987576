export default {
  activeFamilyTreeIdState(state, getters) {
    return state.activeTreeId || getters.userFamilyTreeIdState;
  },
  familyTreeDetailsIdState(state) {
    return state.detailsId;
  },
  familyTreeNameState(state) {
    return state.name;
  },
  familyTreeHomePerson(state) {
    return state.homePerson || {};
  },
  familyTreeMapImageState(state) {
    return state.mapImage;
  },
  familyTreeMapImageScaleState(state) {
    return state.mapImageScale;
  },
  familyTreeMapImageExistsState(state) {
    return !!state.mapImage;
  },
  familyTreeMapImageHashState(state) {
    return state.mapImageHash;
  },
  familyTreeMapImageLoadingState(state) {
    return state.mapImageLoading;
  },
  familyTreeIsMiniMapVisibleState(state) {
    return state.isMiniMapVisible;
  },
  familyTreeStartPersonIdState(state) {
    return state.startPersonId;
  },
  familyTreeStartPersonYState(state) {
    return state.startPersonY;
  },
  familyTreeStartFocusPersonIdState(state) {
    return state.focusPersonId || state.startPersonId;
  },
  familyTreeFocusPersonIdState(state) {
    return state.focusPersonId;
  },
  treeCardMenuShownPersonIdState(state) {
    return state.cardMenuShownPersonId;
  },
  familyTreeDrawLoadingState(state) {
    return state.drawLoading;
  },
  familyTreeRefreshRequestState(state) {
    return state.refreshRequest;
  },
  familyTreePersonsDrawnState(state) {
    return state.personsDrawn;
  },
  familyTreeLinesDrawnState(state) {
    return state.linesDrawn;
  },
  familyTreeDrawnWidthState(state) {
    return state.treeWidth;
  },
  familyTreeDrawnHeightState(state) {
    return state.treeHeight;
  },
  familyTreeDrawnMarginXState(state) {
    return state.treeMarginX;
  },

  familyTreeStartPersonState(state, getters) {
    const persons = state.personsDrawn;
    return persons && persons.find(p => p.is_start_person);
  },
  familyTreePersonsByIdState(state, getters) {
    const persons = state.personsDrawn;
    let byId = {};
    for (let person of persons || []) {
      byId[person.object_id] = person;
    }
    return byId;
  },
  isFamilyTreeWriteAllowedState(state) {
    return state.isWriteAllowed;
  },
  isFamilyTreePublicState(state) {
    return state.isPublic;
  },
  familyTreeMembersState(state) {
    return state.treeMembers;
  },
  isUserOwnerFamilyTreeState(state) {
    return state.isUserOwner;
  },
  familyTreePreferencesState(state) {
    return state.preferences;
  },
  familyTreeBloodRelativesIdsState(state) {
    return state.bloodRelativesIds;
  },
  familyTreeIsLangChineseState(state) {
    return state.isLangChinese;
  },
  familyTreeListState(state) {
    return state.list || [];
  },
  familyTreesByIdState(state) {
    if (!state.list) {
      return {};
    }
    return state.list.reduce((acc, obj) => {
      acc[obj.object_id] = obj;
      return acc;
    }, {});
  },
  familyTreeOwnedListState(state) {
    return (state.list || []).filter(tree => tree.is_owner);
  },
  familyTreeEditableListState(state) {
    return (state.list || []).filter(tree => tree.is_owner || tree.is_editor);
  },
  familyTreeListLoadingState(state) {
    return state.listLoading;
  },
  familyTreeUpdateLoadingByIdState(state) {
    return state.updateLoadingById;
  },
  familyTreePersonsSearchListState(state) {
    return state.personsSearchList || [];
  },
  familyTreePersonsSearchListLoadingState(state) {
    return state.personsSearchListLoading;
  },
  familyTreePersonsSearchListMetaState(state) {
    return state.personsSearchListMeta || {};
  },
  familyTreeMostTaggedLocationsState(state) {
    return state.mostTaggedLocations;
  },
  familyTreeMostTaggedLocationsLoadingState(state) {
    return state.mostTaggedLocationsLoading;
  },
  familyTreeSurnamesState(state) {
    return state.surnames;
  },
  familyTreeSurnamesLoadingState(state) {
    return state.surnamesLoading;
  },
  treeClanMetaState(state) {
    return state.treeClanMeta;
  },

  familyTreeDetailsState(state) {
    return state.details || {};
  },
  familyTreeDetailsLoadingState(state) {
    return state.detailsLoading;
  },
  familyTreeAddMembersLoadingState(state) {
    return state.treeAddMembersLoading;
  },
  familyTreeEditMemberLoadingState(state) {
    return state.treeEditMemberLoading;
  },
  familyTreeDeleteMemberLoadingState(state) {
    return state.treeDeleteMemberLoading;
  },

  treeBreadcrumbsState(state) {
    return state.breadcrumbs;
  },
  needShowBreadCrumbsState(state, getters) {
    return getters.userIsLoggedInState && getters.treeBreadcrumbsState && getters.treeBreadcrumbsState.persons.length;
  },
  isExpandControlChangedState(state) {
    return state.isExpandControlChanged;
  },
  createPrefillSurnamesState(state) {
    return state.createPrefillSurnames;
  },
};
