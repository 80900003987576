import network from '@common/network/network';
import {responseToBase64String} from '@common/utils/utils';

export default {
  fetchFamilyTreeMapAction(context, {familyTreeId, colorCode, startPerson, mapHash}) {
    context.commit('setFamilyTreeMapImageLoadingState', true);
    return network.familyTree
      .getMap({
        id: familyTreeId,
        color: colorCode,
        start_person_id: startPerson,
        map_hash: mapHash,
        scale: context.getters.familyTreeMapImageScaleState,
      })
      .then(response => {
        context.commit('setFamilyTreeMapImageState', responseToBase64String(response));
      })
      .catch(() => {
        context.commit('setFamilyTreeMapImageState', null);
      })
      .finally(() => {
        context.commit('setFamilyTreeMapImageLoadingState', false);
      });
  },
  fetchFamilyTreeMapDiffAction(context, params = {}) {
    context.commit('setFamilyTreeDrawLoadingState', true);

    const writeAllowed = context.getters.isFamilyTreeWriteAllowedState;
    params = {...params, id: params.id || 'my', with_ghosts: writeAllowed ? 1 : 0};
    return new Promise((resolve, reject) => {
      network.familyTree
        .getDrawnDiff(params)
        .then(response => {
          if (!response) {
            // if http status is 204 do full load
            context.dispatch('fetchFamilyTreeAction', params);
            resolve();
          }

          const startPersonId = params.start_person_id;
          context.commit('setFamilyTreeBloodRelativesIdsState', response.blood_relatives_ids);
          context.commit('mutateFamilyTreePersonsByDiff', {
            new_persons: response.new_persons,
            remove_persons: response.remove_persons,
            data_update: response.data_update,
          });
          context.commit('mutateFamilyTreeLinesByDiff', {
            new_lines: response.new_lines,
            remove_lines: response.remove_lines,
          });
          context.commit('setFamilyTreeDrawnWidthState', response.tree_width);
          context.commit('setFamilyTreeDrawnHeightState', response.tree_height);
          context.commit('setFamilyTreeDrawnMarginXState', response.tree_margin_x);
          context.commit('setIsExpandControlChangedState', response.has_state);

          context.commit('setFamilyTreeMapImageHashState', response.map_hash);

          const startPerson = context.getters.familyTreePersonsByIdState[startPersonId];
          context.commit('setFamilyTreeStartPersonYState', startPerson.draws ? startPerson.draws[0].position.y : null);

          // const drawnPersons = context.getters.familyTreePersonsDrawnState;

          context.dispatch('fetchFamilyTreeMapAction', {
            familyTreeId: params.id,
            colorCode: response.preferences.color_code_gender ? '1' : '0',
            startPerson: startPersonId,
            mapHash: response.map_hash,
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreeDrawLoadingState', false);
          context.commit('setFamilyTreeRefreshRequestState', true);
        });
    });
  },
  fetchFamilyTreeAction(context, params = {}) {
    console.log('full load');
    context.commit('setFamilyTreeDrawLoadingState', true);
    params = {...params, id: params.id || 'my'};
    return new Promise((resolve, reject) => {
      network.familyTree
        .getDrawn(params)
        .then(response => {
          const startPerson = response.persons.find(p => p.is_start_person);
          const startPersonId = startPerson.object_id;
          context.commit('setFamilyTreeNameState', response.name);
          context.commit('setFamilyTreeHomePerson', response.home_person);
          context.commit('setFamilyTreePersonsDrawnState', response.persons);
          context.commit('setFamilyTreeLinesDrawnState', response.lines);
          context.commit('setFamilyTreeDetailsIdState', response.id);
          context.commit('setActiveFamilyTreeIdState', response.id);
          context.commit('setFamilyTreeStartPersonIdState', startPersonId);
          context.commit('setFamilyTreeStartPersonYState', startPerson.draws ? startPerson.draws[0].position.y : null);
          context.commit('setFamilyTreeIsWriteAllowedState', response.is_write_allowed);
          context.commit('setFamilyTreeIsUserOwnerState', response.is_user_owner);
          context.commit('setFamilyTreeIsPublicState', response.is_public);
          context.commit('setFamilyTreeMembersState', response.tree_members);
          context.commit('setFamilyTreeDrawnWidthState', response.tree_width);
          context.commit('setFamilyTreeDrawnHeightState', response.tree_height);
          context.commit('setFamilyTreeDrawnMarginXState', response.tree_margin_x);
          context.commit('setFamilyTreePreferencesState', response.preferences);
          context.commit('setFamilyTreeBloodRelativesIdsState', response.blood_relatives_ids);
          context.commit('setFamilyTreeMapImageHashState', response.map_hash);
          context.commit('setOnceShowFindOwnedTreeTooltipState', !response.is_user_owner);
          context.commit('setIsExpandControlChangedState', response.has_state);

          const breadcrumbs = context.getters.treeBreadcrumbsState;
          if (breadcrumbs && breadcrumbs.treeId === response.id) {
            context.commit('handleTreeBreadcrumbsState', {person: startPerson});
          } else {
            context.commit('initTreeBreadcrumbsState', {treeId: response.id, homePerson: response.home_person});
          }
          context.dispatch('fetchFamilyTreeMapAction', {
            familyTreeId: params.id,
            colorCode: response.preferences.color_code_gender ? '1' : '0',
            startPerson: startPersonId,
            mapHash: response.map_hash,
          });
          resolve();
        })
        .catch(async error => {
          console.log('error!', error);
          await context.dispatch('cleanupTreeStateAction');
          if (parseInt(context.getters.activeFamilyTreeIdState) === parseInt(params.id)) {
            context.commit('clearActiveFamilyTreeIdState');
          }
          reject(error);
        })
        .finally(() => {
          context.commit('setFamilyTreeDrawLoadingState', false);
          // context.commit('setFamilyTreeRefreshRequestState', true);
        });
    });
  },
  cleanupTreeStateAction(context) {
    context.commit('setFamilyTreeNameState', null);
    context.commit('setFamilyTreeHomePerson', null);
    context.commit('setFamilyTreePersonsDrawnState', []);
    context.commit('setFamilyTreeLinesDrawnState', []);
    context.commit('setFamilyTreeDetailsIdState', null);
    context.commit('setFamilyTreeStartPersonIdState', null);
    context.commit('setFamilyTreeStartPersonYState', null);
    context.commit('setFamilyTreeIsWriteAllowedState', false);
    context.commit('setFamilyTreeIsUserOwnerState', false);
    context.commit('setFamilyTreeIsPublicState', false);
    context.commit('setFamilyTreeMembersState', []);
    context.commit('setFamilyTreeDrawnWidthState', null);
    context.commit('setFamilyTreeDrawnHeightState', null);
    context.commit('setFamilyTreeDrawnMarginXState', 0);
    context.commit('setFamilyTreePreferencesState', {});
    context.commit('setIsExpandControlChangedState', false);
  },
  fetchFamilyTreeListAction(context, {fields} = {}) {
    context.commit('setFamilyTreeListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTree
        .list({fields})
        .then(response => {
          context.commit('setFamilyTreeListState', response);
        })
        .finally(() => {
          context.commit('setFamilyTreeListLoadingState', false);
        });
    });
  },
  fetchFamilyTreeDetailsAction(context, id) {
    context.commit('setFamilyTreeDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTree
        .details({id})
        .then(response => {
          context.commit('setFamilyTreeDetailsState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeDetailsLoadingState', false);
        });
    });
  },
  updateFamilyTreeAction(context, {id, is_public, name, home_person_id}) {
    context.commit('setFamilyTreeUpdateLoadingByIdState', {id, loading: true});
    return new Promise((resolve, reject) => {
      network.familyTree
        .update({id, is_public, name, home_person_id})
        .then(response => {
          context.commit('mutateFamilyTreeNameInListState', {id, name: response.name});
          if (context.getters.familyTreeDetailsIdState === id) {
            context.commit('setFamilyTreeNameState', response.name);
            context.commit('setFamilyTreeHomePerson', response.home_person);
          }
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeUpdateLoadingByIdState', {id, loading: false});
        });
    });
  },
  searchPersonsInFamilyTreeAction(context, {familyTreeId, q}) {
    context.commit('setFamilyTreePersonsSearchListLoadingState', true);

    const search = familyTreeId
      ? network.familyTree.searchPersons({id: familyTreeId, query: q})
      : network.familyTree.searchPersonsInAllTrees({query: q});

    return new Promise((resolve, reject) => {
      search
        .then(response => {
          context.commit('setFamilyTreePersonsSearchListState', response.objects);
          context.commit('setFamilyTreePersonsSearchListMetaState', response.meta);
          resolve(response);
        })
        .finally(() => {
          context.commit('setFamilyTreePersonsSearchListLoadingState', false);
        });
    });
  },
  searchMostTaggedLocationsInFamilyTreeAction(context, {familyTreeId, q}) {
    context.commit('setFamilyTreeMostTaggedLocationsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTree
        .getMostTaggedLocations({id: familyTreeId, q})
        .then(response => {
          context.commit('setFamilyTreeMostTaggedLocationsState', response);
          resolve(response);
        })
        .finally(() => {
          context.commit('setFamilyTreeMostTaggedLocationsLoadingState', false);
        });
    });
  },
  fetchFamilyTreeSurnamesListAction(context, familyTreeId) {
    context.commit('setFamilyTreeSurnamesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTree
        .getChineseSurnames({id: familyTreeId})
        .then(response => {
          context.commit('setFamilyTreeSurnamesState', response.objects || response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeSurnamesLoadingState', false);
        });
    });
  },
  fetchFamilyTreeClanMetaAction(context, {familyTreeId, surname}) {
    return new Promise((resolve, reject) => {
      network.familyTree
        .geTreeClanMeta({id: familyTreeId, q: surname})
        .then(response => {
          context.commit('setTreeClanMetaState', response);
          resolve(response);
        })
        .catch(reject);
    });
  },
  createFamilyTreeAction(context, {start_person}) {
    return new Promise((resolve, reject) => {
      network.familyTree.create({start_person}).then(resolve).catch(reject);
    });
  },
  deleteFamilyTreeAction(context, {id}) {
    return new Promise((resolve, reject) => {
      network.familyTree.delete({id}).then(resolve).catch(reject);
    });
  },
  familyTreeAddMembersAction(context, {id, emails}) {
    context.commit('setFamilyTreeAddMembersLoadingState', true);
    return new Promise((resolve, reject) => {
      network.familyTree
        .addMembers({id, emails})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeAddMembersLoadingState', false);
        });
    });
  },
  familyTreeEditMemberAction(context, {id, email, is_editor}) {
    context.commit('setFamilyTreeEditMemberLoadingState', {email, loading: true});
    return new Promise((resolve, reject) => {
      network.familyTree
        .editMember({id, email, is_editor})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeEditMemberLoadingState', {email, loading: false});
        });
    });
  },
  familyTreeDeleteMemberAction(context, {id, email}) {
    context.commit('setFamilyTreeDeleteMemberLoadingState', {email, loading: true});
    return new Promise((resolve, reject) => {
      network.familyTree
        .deleteMember({id, email})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyTreeDeleteMemberLoadingState', {email, loading: false});
        });
    });
  },
};
