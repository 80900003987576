import omit from 'lodash/omit';

const USER_FIELDS_MAPPING = {
  username: 'username',
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  photo: 'photo',
  surname_en: 'surnameEn',
  surname_ch: 'surnameCh',
  subscribe_to_newsletter: 'subscribeToNewsletter',
  email_verified: 'emailVerified',
  resource_uri: 'resourceUri',
  social_auth: 'socialAccounts',
  is_staff: 'isStaff',
  is_shared: 'isShared',
  key: 'key',
  fws_username: 'fwsUsername',
  is_subscribed: 'isSubscribed',
  is_onboarded: 'isOnboarded',
  onboarding_step: 'onboardingStep',
};

export default {
  setUserUsernameState(state, username) {
    state.username = username;
  },
  setUserFirstNameState(state, firstName) {
    state.firstName = firstName;
  },
  setUserLastNameState(state, lastName) {
    state.lastName = lastName;
  },
  setUserEmailState(state, email) {
    state.email = email;
  },
  setUserPhotoState(state, photo) {
    state.photo = photo;
  },
  setUserSurnameEnState(state, surnameEn) {
    state.surnameEn = surnameEn;
  },
  setUserSurnameChState(state, surnameCh) {
    state.surnameCh = surnameCh;
  },
  setUserSubscribeToNewsletterState(state, subscribeToNewsletter) {
    state.subscribeToNewsletter = subscribeToNewsletter;
  },
  setUserEmailVerifiedState(state, emailVerified) {
    state.emailVerified = emailVerified;
  },
  setUserResourceUriState(state, resource_uri) {
    state.resourceUri = resource_uri;
  },
  setUserIsSubscribedState(state, data) {
    state.isSubscribed = data;
  },
  setUserKeyState(state, key) {
    state.key = key;
  },
  setResponseUserState(state, responseData) {
    Object.keys(responseData).forEach(key => {
      const stateKey = USER_FIELDS_MAPPING[key];
      if (stateKey) {
        state[stateKey] = responseData[key];
      }
    });
    if (responseData.hasOwnProperty('hints_tree_ids')) {
      state.hintsTreeIds = responseData.hints_tree_ids;
    }
  },
  clearUserState(state) {
    localStorage.removeItem('session_key');
    Object.values(USER_FIELDS_MAPPING).forEach(key => {
      state[key] = null;
    });
    state.isOnboarded = true; // to avoid showing the onboarding
    state.showFindOwnedTreeTooltip = null;
    state.impersonatedUser = null;
    state.familyTreeId = null;
    state.hintsTreeIds = {};
    state.impersonatedUserFamilyTreeId = null;
  },
  setSocialAccountsState(state, socialAccounts) {
    state.socialAccounts = socialAccounts;
  },
  addSocialAccountState(state, socialAccount) {
    state.socialAccounts = [...state.socialAccounts, socialAccount];
  },
  setFacebookConnectErrorState(state, error) {
    state.facebookConnectError = error;
  },
  setGoogleConnectErrorState(state, error) {
    state.googleConnectError = error;
  },
  setImpersonatedUserState(state, user) {
    state.impersonatedUser = user;
  },
  setUserFamilyTreeIdState(state, id) {
    state.familyTreeId = id;
  },
  setUserHintsTreeIdsState(state, obj) {
    state.hintsTreeIds = obj;
  },
  decrementUserHintsTreeIdsState(state, treeId) {
    if (state.hintsTreeIds[treeId]) {
      const newCount = state.hintsTreeIds[treeId] - 1;
      state.hintsTreeIds = newCount ? {...state.hintsTreeIds, [treeId]: newCount} : omit(state.hintsTreeIds, treeId);
    }
  },
  setImpersonatedUserFamilyTreeIdState(state, id) {
    state.impersonatedUserFamilyTreeId = id;
  },
  advanceUserOnboardingState(state, {isOnboarded, onboardingStep}) {
    state.isOnboarded = isOnboarded;
    state.onboardingStep = onboardingStep;
  },
  setOnceShowFindOwnedTreeTooltipState(state, show) {
    const varAllowChange = state.showFindOwnedTreeTooltip === null || state.showFindOwnedTreeTooltip === true;
    // change var if user is logged in and either views first tree, or dismisses the tooltip
    if (Boolean(state.key) && varAllowChange) {
      state.showFindOwnedTreeTooltip = show;
    }
  },
  setTutorialsShownState(state, data) {
    state.tutorialsShown = data;
  },
};
