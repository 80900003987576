import allRecordsSearchModule from '@common/store/all-records-search';
import cemeteryModule from '@common/store/cemetery';
import clanModule from '@common/store/clan';
import clanPedigreeModule from '@common/store/clan-pedigree';
import clanPedigreesSearchModule from '@common/store/clan-pedigrees-search';
import dictionaryModule from '@common/store/dictionary';
import galleryModule from '@common/store/gallery';
import glossaryModule from '@common/store/glossary';
import helperModule from '@common/store/helper';
import newspapersModule from '@common/store/newspapers';
import placeModule from '@common/store/place';
import sourceModule from '@common/store/source';
import sourceMentionModule from '@common/store/source-mention';
import sourceSearchModule from '@common/store/source-text-search';
import surnamesSearchModule from '@common/store/surnames-search';
import switchesModule from '@common/store/switches';
import wikiModule from '@common/store/wiki';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import createMutationsSharer from 'vuex-shared-mutations';

import commonActions from './actions';
import commonGetters from './getters';
import tracesModule from './modules/ancestry-traces';
import ownedClanPedigreeModule from './modules/clan-pedigree-owned';
import savedClanPedigreeModule from './modules/clan-pedigree-saved';
import contactUsFormModule from './modules/contact-us-form';
import familyTreeModule from './modules/family-tree';
import familyTreeHintsModule from './modules/family-tree-hints';
import familyTreeLibraryModule from './modules/family-tree-library';
import familyTreeLineageModule from './modules/family-tree-lineage';
import familyTreeOnboardingModule from './modules/family-tree-onboarding';
import familyTreePersonModule from './modules/family-tree-person';
import familyTreeRelationsModule from './modules/family-tree-relations';
import familyTreeSavedModule from './modules/family-tree-saved';
import familyWebsiteModule from './modules/family-website';
import newsModule from './modules/news';
import orderModule from './modules/order';
import paymentModule from './modules/payment';
import registerModule from './modules/register';
import subscriptionModule from './modules/subscription';
import supportModule from './modules/support';
import teamMembersModule from './modules/team-members';
import testimonialsModule from './modules/testimonials';
import userModule from './modules/user';
import commonMutations from './mutations';
import commonState from './state';

/* eslint-disable no-unused-vars */
const vuexLocalStorage = new VuexPersist({
  key: 'store',
  storage: window.localStorage,
  reducer: state => {
    const {clanPedigreeDetailsPreviousRoute, previousRoute, notifications, ...helperState} = state.helperState;
    const {isLangChinese, activeTreeId, breadcrumbs} = state.familyTreeState;

    return {
      userState: state.userState,
      helperState: helperState,
      familyTreeState: {isLangChinese, activeTreeId, breadcrumbs},
      windowWidth: state.windowWidth,
    };
  },
});

let sharedMutations = Object.keys(userModule.mutations);
sharedMutations.push('addNotificationState');
sharedMutations.push('dismissNotificationInState');
const sharedMutationsPlugin = createMutationsSharer({predicate: sharedMutations});

Vue.use(Vuex);

export default new Vuex.Store({
  actions: commonActions,
  getters: commonGetters,
  mutations: commonMutations,
  state: commonState,
  modules: {
    cemeteryState: cemeteryModule,
    clanState: clanModule,
    clanPedigreeState: clanPedigreeModule,
    clanPedigreesSearchState: clanPedigreesSearchModule,
    savedClanPedigreeState: savedClanPedigreeModule,
    ownedClanPedigreeState: ownedClanPedigreeModule,
    teamMembersState: teamMembersModule,
    tracesState: tracesModule,
    testimonialsState: testimonialsModule,
    newsState: newsModule,
    familyTreeState: familyTreeModule,
    familyTreePersonState: familyTreePersonModule,
    familyTreeRelationsState: familyTreeRelationsModule,
    familyTreeLineageState: familyTreeLineageModule,
    familyTreeOnboardingState: familyTreeOnboardingModule,
    familyTreeSavedState: familyTreeSavedModule,
    familyTreeHintsState: familyTreeHintsModule,
    familyWebsiteState: familyWebsiteModule,
    userState: userModule,
    glossaryState: glossaryModule,
    galleryState: galleryModule,
    helperState: helperModule,
    familyTreeLibraryState: familyTreeLibraryModule,
    contactUsFormState: contactUsFormModule,
    placeState: placeModule,
    dictionaryState: dictionaryModule,
    surnamesSearchState: surnamesSearchModule,
    paymentState: paymentModule,
    switchesState: switchesModule,
    registerState: registerModule,
    wikiState: wikiModule,
    newspapersState: newspapersModule,
    sourceState: sourceModule,
    sourceMentionState: sourceMentionModule,
    sourceSearchState: sourceSearchModule,
    subscriptionState: subscriptionModule,
    allRecordsSearchState: allRecordsSearchModule,
    orderState: orderModule,
    supportState: supportModule,
  },
  plugins: [vuexLocalStorage.plugin, sharedMutationsPlugin],
});
