export default {
  mcr_zhang_clan_demo_id: 'mcr_zhang_clan_demo_id',
  store: 'store',
  imageExtensions: ['jpg', 'jpe', 'jpeg', 'png', 'jfif', 'gif', 'svg', 'bmp'],
  videoExtensions: ['mp4', 'webm', 'ogg', 'ogv', 'mov', 'avi', 'wmv', 'flv', '3gp', 'mkv'],
  audioExtensions: ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a', 'aiff', 'ape', 'alac'],
  gedcomExtensions: ['ged', 'gdz'],
  maxFileSizeMB: 100,
  requestZupuAccessModalName: 'request-zupu-access',
  buyZupuSuccessModalName: 'buy-zupu-success',
  zupuAccessProduct: 'Permanent Access to Zupu',
  platformFeaturesOverviewID: 'features-overview',
  PROD_PROFILE: 'prod',
  MODAL_NAME_QUICK_SIDEBAR: 'quick-sidebar',
  ONBOARDING_STEPS: [
    'familytree-onboarding-start',
    'familytree-onboarding-me',
    'familytree-onboarding-mother',
    'familytree-onboarding-father',
    'familytree-onboarding-grandparent',
  ],
  FACT_TYPES_PLACE_ID_WARNING: ['arrival', 'naturalization'],
};
